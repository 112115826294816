<template>
<section class="gold-card p-2">
    <!-- écran large -->
    <VueSlickCarousel class="computer" v-bind="settings" v-if="randItem.length>0 && !isloaded">
        <div v-for="item in randItem" :key="item.id">
            <div class="card gold justify-content-center m-4">
                <template v-if="Array.isArray(item.image) && item.image.length">
                        <div v-for="(val,key,index) of item.image" :key="index">
                            <span v-if="key == 0" >
                                <picture @click="productInfo(item.ulid)">
                                    <source class="card-img-top"  :srcset="val.content.webpImage" type="image/webp">
                                    <img draggable="false" class="card-img-top"  :src="val.content.originalImage"  alt="Card image cap" loading="lazy">
                                </picture>
                            </span>
                        </div>
                </template>
                <img   v-else  class="card-img-top" src="@/assets/images/import/default-image.jpg" @click="productInfo(item.ulid)" alt="Card image cap" loading="lazy">
                <div class="card-body p-3">
                    <h5 class="card-title pointer" @click="productInfo(item.ulid)">{{item[title]}}</h5>
                    <div class="d-flex justify-content-between">
                        <p class="card-text">{{item[description]}}</p>
                        <h2 class="text-muted">{{roundNumber(item[price] * 1.2)}}€</h2>
                    </div>
                </div>
            </div>
        </div>
    </VueSlickCarousel>
    <!-- mobile version -->
    <div class="row product-item-wrapper scrollcards mobile">
        <div class="col-lg-3 col-md-4 col-sm-12 my-3 scrollcards-item" v-for="item in randItem" :key="item.id">
            <div class="card gold justify-content-center my-4"  @click="productInfo(item.ulid)">
                <template v-if="Array.isArray(item.image) && item.image.length">
                        <div v-for="(val,key,index) of item.image" :key="index">
                            <span v-if="key == 0">
                                <picture>
                                    <source class="card-img-top"  :srcset="val.content.webpImage" type="image/webp">
                                    <img class="card-img-top"  :src="val.content.originalImage" alt="Card image cap" loading="lazy">
                                </picture>
                            </span>
                        </div>
                </template>
                <img   v-else  class="card-img-top" src="@/assets/images/import/default-image.jpg" alt="Card image cap" loading="lazy">
                <div class="card-body p-3">
                    <h5 class="card-title">{{item[title]}}</h5>
                    <div class="d-flex justify-content-between">
                        <p class="card-text">{{item[description]}}</p>
                        <h2 class="text-muted">{{roundNumber(item[price] * 1.2)}}€</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { priceMixin } from '../../pages/mixins/price'
export default {
    name: 'gold-card',
    mixins:[priceMixin],
    components: {
        VueSlickCarousel
    },
    data () {
        return {
            produits: [1, 2, 3, 4, 5],
            items: [],
            settings: {
                "dots": true,
                // "focusOnSelect": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "touchThreshold": 5
                },
            isloaded: true
        }
    },
    props: {
        id: String,
        scrollcards: Boolean,
        entitytype: String,
        card: Array,
        title: String,
        description:String,
        price: String,
        column: String,
        sectionTitle: String,
        editable: Boolean
    },
    computed:{
        randItem:function(){
            let array = this.items
            // rand  items 
            var currentIndex = array.length, temporaryValue, randomIndex;
            while (0 !== currentIndex) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }
            return array;
        }
    },
    methods: {
        getEntitytype () {
            if(this.entitytype) {
            let url = this.entitytype
            axios.get(url)
                .then(
                    resgetProducts => {
                        let arr = resgetProducts.data.result
                        arr = arr.filter((item, index, self) =>
                        index === self.findIndex((p) => (
                            p.name === item.name
                        ))
                        )
                        if (arr.isactive) {
                           arr= arr.filter(element => element.isactive)
                        }
                        this.items = arr.slice(0,9)
                        this.isloaded = false
                    }
                )
                .catch(
                    errgetProducts => {
                        console.log(errgetProducts)
                    }
                )
            }else if(this.card){
                this.items = this.card
            }else{
                this.items=[]
            }
        },
        //scrollcards
        scrollright() {
            console.log(this.id);
            document.getElementById(`${this.id}`).scrollLeft += 1000;
        },
        scrollleft() {
            console.log(this.id);
            document.getElementById(`${this.id}`).scrollLeft -= 1000;
        },
        deleteCard(item) {
            let index = this.items.indexOf(item)
            console.log(index)
            this.items.splice(index, 1)
        },
        productInfo (ulid) {
            console.log("product info");
            this.$router.push(
                {
                name: 'product',
                params: {ulid: ulid}
                }
            )
        }
    },
    mounted () {
        this.getEntitytype ()
    }
}
</script>

<style scoped>
.scrollcards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: thin
}
.scrollcards-item{
    /* display: inline-block !important */
    flex: 0 0 auto;
}


.carousel-control-prev-icon {
    background-image: url('../../assets/images/import/arrow-preview.png');
    width: 50px;
    height: 50px;
    margin: 0px 81px 0px -122px
    }
.carousel-control-next-icon {
    background-image: url('../../assets/images/import/arrow-next.png');
    width: 50px;
    height: 50px;
    margin: 0px -121px 0px 79px
    }

img {
    /* width: auto; */
    height: 300px;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    pointer-events: none;
}

.card:hover::after{
    content: "";
    position: absolute;
    width: 105%;
    height: 105%;
    z-index: -1;
    background: linear-gradient(90deg, rgba(197,140,35,1) 0%, rgba(242,229,151,1) 35%, rgba(197,140,35,1) 68%, rgba(242,229,151,1) 100%);
    border-radius: 5px;
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
}
.card::after {
    content: "";
    position: absolute;
    width: 103%;
    height: 101%;
    right: -2%;
    z-index: -1;
    background: linear-gradient(90deg, rgba(197,140,35,1) 0%, rgba(242,229,151,1) 35%, rgba(197,140,35,1) 68%, rgba(242,229,151,1) 100%);
    transition: ease-in-out 0.2s;
}
.card {
    background:none;
    z-index: 2;
    padding: 1rem;
    position: relative;
    /* background: linear-gradient(90deg, rgba(197,140,35,1) 0%, rgba(242,229,151,1) 35%, rgba(197,140,35,1) 68%, rgba(242,229,151,1) 100%); */
    padding: 5px;
    perspective: 100px;
    transition: transform 0.2s
}
.card:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.card-body{
    border: black 1px solid;
    background: #fff;
    transition: transform 0.5s;
}
.card h2 {
    font-size: 40px;
    margin-top: 1rem
}
.card p {
    font-size: 18px;
    margin-top: 1rem
}
.card-title{
    /* text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            /* line-clamp: 1;
    -webkit-box-orient: vertical; */
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}
.card-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "...";
}
</style>